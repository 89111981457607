<template>
  <v-container fluid>
    <div>
      <!-- le workflow applicatif -->
      <div class="d-flex justify-center" flat tile>
        <Workflow
          width="600"
          height="180"
          :steps="workflowSteps"
          :currentStep="workflowIndex"
          :labelWidth="200"
          :lineWidth="140"
        ></Workflow>
      </div>

      <v-row justify="center">
        <v-col xl="6" lg="8" md="8" sm="10" xs="12">
          <!-- le titre et le bouton retour -->
          <div class="d-flex justify-center">
            <TitleAndReturnComponent title="Produit" />
          </div>

          <!-- l'affichage de la table -->
          <v-card flat outlined class="my-4">
            <v-card-title class="font-weight-regular">
              <v-row no-gutters justify="space-between">
                <div>Ajouter un produit</div>
              </v-row>
            </v-card-title>

            <!-- la catégorie de produit -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="4" justify="center" align-self="center">
                  <div class="label-field capitalize">catégorie de produit</div>
                </v-col>
                <v-col cols="8" align-self="center">
                  <v-autocomplete
                    placeholder="Sélectionner une catégorie"
                    :items="categoryProduct"
                    item-text="label"
                    no-data-text="aucune catégorie"
                    v-model="selectedCategory"
                    return-object
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- Le nom digital -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="4" justify="center" align-self="center">
                  <div class="label-field capitalize">nom digital</div>
                </v-col>
                <v-col cols="8" align-self="center">
                  <v-text-field 
                    dense 
                    v-model="digitalName"
                    @blur="digitalNameFieldOut"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- Le nom commercial -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="4" justify="center" align-self="center">
                  <div class="label-field capitalize">nom commercial</div>
                </v-col>
                <v-col cols="8" align-self="center">
                  <v-text-field dense v-model="commercialName"></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- l'activité -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="4" justify="center" align-self="center">
                  <div class="label-field capitalize">activité</div>
                </v-col>
                <v-col cols="8" align-self="center">
                  <v-autocomplete
                    placeholder="Sélectionner une activité"
                    :items="availableActivities"
                    item-text="name"
                    no-data-text="aucune activité"
                    v-model="activity"
                    return-object
                    clearable
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>
            
            <!-- la sous-activité -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="4" justify="center" align-self="center">
                  <div class="label-field capitalize">sous-activité</div>
                </v-col>
                <v-col cols="8" align-self="center">
                  <v-autocomplete
                    placeholder="Sélectionner une sous-activité"
                    :items="availableSubActivities"
                    item-text="label"
                    no-data-text="aucune sous-activité"
                    v-model="subActivity"
                    return-object
                    clearable
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- la spécialité médicale -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="4" justify="center" align-self="center">
                  <div class="label-field capitalize">spécialité médicale</div>
                </v-col>
                <v-col cols="8" align-self="center">
                  <v-autocomplete
                    placeholder="Sélectionner une spécialité médicale"
                    :items="medicalSpecialities"
                    item-text="name"
                    no-data-text="aucune spécialité médicale"
                    v-model="medicalSpeciality"
                    return-object
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- la description -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="4" justify="center" align-self="start">
                  <div class="label-field capitalize">description</div>
                </v-col>
                <v-col cols="8" align-self="center">
                  <v-textarea dense v-model="description"></v-textarea>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- Les description spécifiques -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="4" justify="center" align-self="start">
                  <div class="label-field capitalize">
                    description(s) spécifique
                  </div>
                </v-col>
                <v-col cols="8" align-self="start">
                  <div v-for="(specificDescription, i) in specificDescriptions" :key="i">
                    <v-row no-gutters align="start" justify="space-between">
                      <v-col cols="4" class="px-1 pt-1">
                        <div>
                          {{ specificDescription.establishmentName }}
                        </div>
                      </v-col>

                      <v-col cols="7" class="px-1" >
                        <v-textarea 
                        dense 
                        v-model="specificDescription.description">
                        </v-textarea>
                      </v-col>

                      <v-col cols="1" class="px-1">
                        <v-btn
                          icon
                          color="primary"
                          @click="removeSpecificDescription(i)"
                        >
                          <v-icon>mdi-window-close</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters align="start" justify="space-between">
                      <v-col cols="4" class="px-1">
                        <v-autocomplete
                          v-model="selectedEstablishment"
                          :items="availableEstablishment"
                          item-text="digitalName"
                          return-object
                          placeholder="Associer un établissement"
                          hint="Associer un établissement, une description et appuyez sur '+'"
                          clearable
                          no-data-text="aucun établissement"
                        >
                        </v-autocomplete>
                      </v-col>

                      <v-col cols="7" class="px-1">
                        <v-textarea
                            placeholder="Ajouter une description"
                            v-model="descriptionEstablishment"
                        ></v-textarea>
                      </v-col>

                      <v-col cols="1" align="center" class="pt-2">
                        <v-btn
                          color="primary"
                          icon
                          @click="addSpecificDescription()"
                          :disabled="!canAddSpecificDescription"
                          ><v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                class="mx-2 my-2 px-4 btn"
                color="primary"
                :disabled="!canSave"
                @click="save"
                >Enregistrer</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";

import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";

import { AvecProductsService } from "@/service/conf/avec_products_service.js";
import { ActivityService } from "@/service/sfr/activity_service.js";
import SubActivitiesService from "@/service/sfr/sub_activity_service.js";
import { MedicalSpecialtiesService } from "@/service/practitioners/medical_specialties_service.js";
import EstablishmentsService from "@/service/etablishment/etablishment_service.js";
import { AvecProductCategoryService } from "@/service/conf/avec_product_category_service.js"

import * as exceptions from "@/service/exception_to_message.js";
import { trimString } from "@/tools/string_tool.js";

export default {
  name: "AddAvecProducts",
  components: { Workflow, TitleAndReturnComponent },

  mixins: [WorkflowMixin, RoleMixin, TableViewEditWorkflowMixin, SnackBarMixin],
  data() {
    return {
      /** le service des entités */
      service: null,
      /** le service des activités */
      serviceActivities: null,
      /** le service des sous-activités */
      serviceSubActivities: null,
      /** le service des spécialités médicales */
      serviceMedicalSpecialities: null,
      serviceEstablishment: null,
      serviceCategoryProduct: null,


      /**en cours de chargement */
      loading: false,

      /** le nom digital */
      digitalName: "",
      /** le nom commercial */
      commercialName: "",
      /** La description */
      description: "",

      /** la liste d'activités */
      activities: [],
      /** l'activité choisie */
      activity: null,

      /** la liste de sous-activités */
      subActivities: [],
      /** la sous-activité choisie */
      subActivity: null,

      /** la liste des activité avec leurs sous-activités */
      activitiesWithSubAct: [],

      /** la liste de spécialité médicale */
      medicalSpecialities: [],
      /** la spécialité médicale choisie */
      medicalSpeciality: null,

      /** La liste des descriptions spécifiques */
      specificDescriptions: [],

      /** La liste des établissement du groupe */
      establishments: [],
      /** L'établissement pour l'ajout d'une description spécifique */
      selectedEstablishment: null,
      /** La description pour l'établissement */
      descriptionEstablishment: null,

      /** La liste des catégorie de produit */
      categoryProduct: [],
      /** La catégorie sélectionnée */
      selectedCategory: null,
    };
  },
  methods: {
    /** Chargement des datas */
    async load() {
      try {
        this.loading = true;
        
        // Récupération de toutes les activités
        this.activities = await this.serviceActivities.getActivities();
        // Récupération de toutes les activités avec leurs sous-activités
        this.activitiesWithSubAct = await this.serviceActivities.getActivitiesWithSubactivities();
        // Récupération de toutes les sous-activités
        this.subActivities = await this.serviceSubActivities.getAllSubActivities();
        // Récupération de toutes les spécialités médicales
        this.medicalSpecialities = await this.serviceMedicalSpecialities.getAll();
        // Récupère la liste des noms digitaux des établissement
        this.establishments = await this.serviceEstablishment.getDigitalNames();
        /** Récupère l'ensemble des catégories de produit */
        this.categoryProduct = await this.serviceCategoryProduct.getAll();


        // Tri des activités par nom
        this.activities.sort(function(a, b) {
          return a.name.localeCompare(b.name);
        });

        // Tri des sous-activités par label
        this.subActivities.sort(function(a, b) {
          return a.label.localeCompare(b.label);
        });

        // Tri des spécialités médicales par nom
        this.medicalSpecialities.sort(function(a, b) {
          return a.name.localeCompare(b.name);
        });

      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    /** Sauvegarde des datas */
    async save() {
      try {
        let item = {};
        item.digitalName = this.digitalName;
        item.commercialName = this.commercialName;
        item.description = this.description;
        item.category = this.selectedCategory.id;

        /** Association de l'activité */
        item.activity = null;
        if (this.activity) {
          item.activity = this.activity.id;
        }

        /** Association de la sous-activité */
        item.subActivity = null;
        if (this.subActivity) {
          item.subActivity = this.subActivity.id;
        }

        /** Association de la spécialité médicale */
        item.medicalSpeciality = null;
        if (this.medicalSpeciality) {
          item.medicalSpeciality = this.medicalSpeciality.id;
        }

        // Sauvegarde de l'entité et retour de l'entité créée
        let entity = await this.service.create(item);

        // Parcours les descriptions spécifiques pour les sauvegardée
        for (let descSpe of this.specificDescriptions) {
          let desc = {};
          desc.description = descSpe.description;
          // Sauvegarde de chaque description spécifique
          await this.service.createUpdateSpecificDescription(descSpe.establishmentId, entity.id, desc);
        }

        this.$router.go(-1);
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "ajouter : " + (exceptions.toMessage(error) || "problème technique")
        );
      }
    },

    /** Suppression d'une description spécifique  */
    removeSpecificDescription(index) {
      if (index != null) {
        this.specificDescriptions.splice(index, 1);
      }
    },

    /** Ajoute une description spécifique pour un établissement */
    addSpecificDescription() {
      let desc = {};

      desc.establishmentName = this.selectedEstablishment.digitalName;
      desc.establishmentId = this.selectedEstablishment.id;
      desc.description = this.descriptionEstablishment;

      this.specificDescriptions.push(desc);

      this.selectedEstablishment = null;
      this.descriptionEstablishment = null;
    },

    /** Méthode appelée lors de l'évènement de sortie de champ nom digital */
    digitalNameFieldOut() {
      this.digitalName = trimString(this.digitalName);
    },

  },
  computed: {
    completed() {
      if (!this.digitalName) return false;

      if (!this.commercialName) return false;

      if (!this.activity) return false;

      if (!this.selectedCategory) return false;

      return true;
    },

    canSave() {
      return this.completed;
    },

    /** Retourne les sous activités disponible en fonction de l'activité choisie */
    availableSubActivities() {
      let available = [];

      // Pas d'activité choisie, on retourne toute les sou act
      if (!this.activity) {
        available = JSON.parse(JSON.stringify(this.subActivities));
      } else {
        // Récupère l'activité dans le tableau d'association act / sous act
        let act = this.activitiesWithSubAct.find((a) => a.activityId == this.activity.id);
        available = JSON.parse(JSON.stringify(act.subActivities));

        // Tri des sous activités par label
        available.sort(function(a, b) {
          return a.label.localeCompare(b.label);
        });
      }

      return available;
    },

    /** Retourne les activités disponible en fonction de la sous activité sélectionnée */
    availableActivities() {
      let available = [];

      // Pas de sous act sélectionnée --> on retourne toutes les activités
      if (!this.subActivity) {
        available = JSON.parse(JSON.stringify(this.activities));
      } else {
        let activitiesWithSelectedSubAct = [];
        for (let activity of this.activitiesWithSubAct) {
          // Récupère le tuple contenant la sous activité sélectionnée
          let found = activity.subActivities.find((s) => s.id == this.subActivity.id);

          if (found) {
            // Ajout de l'id de l'activité si elle est associé à la sous act sélectionnée
            activitiesWithSelectedSubAct.push(activity.activityId);
          }
        }
        // Filtre l'ensembles des activités pour ne garder que les activités trouvé précédemment
        available = this.activities.filter((a) => activitiesWithSelectedSubAct.includes(a.id));
      }

      return available;
    },

    /** Fournit la liste des établissement qui n'ont pas de description spécifique pour ce produit */
    availableEstablishment() {
      let available = [];

      let ids = this.specificDescriptions.map((s) => s.establishmentId);
      available = this.establishments.filter((e) => !ids.includes(e.id));

      return available;
    },

    /** Autorisation la création d'une description spécifique */
    canAddSpecificDescription() {
      if (!this.selectedEstablishment) return false;
      if (!this.descriptionEstablishment) return false;

      return true;
    },
  },
  mounted() {
    this.service = new AvecProductsService(this.$api.getTransmissionsAvecProductsApi());
    this.serviceActivities = new ActivityService(this.$api);
    this.serviceSubActivities = new SubActivitiesService(this.$api);
    this.serviceMedicalSpecialities = new MedicalSpecialtiesService(
      this.$api.getMedicalSpecialitiesApi()
    );
    this.serviceEstablishment = new EstablishmentsService(this.$api);
    this.serviceCategoryProduct = new AvecProductCategoryService(this.$api.getProductCategory());



    // Initialisation du worflow
    this.initTableViewEditWorkflow(this.canEdit, 1);

    this.load();
  },
};
</script>

<style>
</style>